import {DECIMAL_FORMAT} from "../../util/numbers.js";
import './StatusBar.scss'
import Fire from "../util/animation/Fire.jsx";
import Counter from "../util/Counter.jsx";

export default ({
                    header = 'test header',
                    maxValue = 100,
                    currentValue = 50,
                    isBurning = false,
                    change = 0,
                }) => {

    const pct = currentValue / maxValue * 100

    return (
        <div className={`status-bar ${isBurning ? 'is-burning' : ''}`}>
            <h3>
                {header}
            </h3>
            <div className='bar'>
                <div className='bar-pct'>
                    <Percentage
                        value={pct}
                        className='bar-pct'
                    />
                </div>
                <div
                    className='bar-progress'
                    style={{"--width": `${pct}%`}}
                >
                    <Percentage
                        value={pct}
                        className='bar-progress-pct'
                    />
                </div>
                {isBurning &&
                <>
                    <div
                        className='bar-burn'
                        style={{"--width": `${pct}%`}}
                    >
                        <div className='bar-lighting'/>
                        <div className='bar-burn-flame'>
                            <Fire/>
                        </div>
                    </div>
                </>
                }
            </div>
            {change !== 0 ?
                <div className='change'>
                    <Fire/>
                    <Counter
                        amount={change}
                        decimals={0}
                        absolute={true}
                    />
                    <span className='desc'>
                    burned last 24h
                </span>
                </div>
                :
                <div className='values'>
                    <div className='values-current'>
                        <Counter
                            amount={currentValue}
                            decimals={0}
                        />
                    </div>
                    <div className='values-separator'>
                        /
                    </div>
                    <div className='values-max'>
                        <Counter
                            amount={maxValue}
                            decimals={0}
                        />
                    </div>
                </div>
            }
        </div>
    )
}

const Percentage = ({
                        className = "",
                        value = 0,
                    }) => {
    return (
        <div className={`pct ${className}`}>
            <Counter
                amount={value}
                decimals={DECIMAL_FORMAT.FLEX_PERCENTAGE}
                suffix='%'
                noColor={true}
            />
        </div>
    )
}