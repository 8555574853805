import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import {QueryClient, QueryClientProvider,} from '@tanstack/react-query'
import 'chartjs-adapter-moment';
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import store from './ducks/_root-reducer.js'
import {Provider} from 'react-redux';

import {
    ArcElement,
    BarController,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    TimeScale,
    Tooltip,
} from "chart.js";

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Filler,
    TimeScale,
    LineController,
    BarController,
    BarElement,
);

dayjs.extend(relativeTime)

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <App/>
            </QueryClientProvider>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
