import {useQuery} from "@tanstack/react-query";
import {getStatusApi} from "../../api/fetch.js";
import {SEC} from "../../util/time-and-date.js";
import {INITIAL_TOKEN_SUPPLY} from "../../util/misc.js";
import StatusBar from "./StatusBar.jsx";
import Chart from "../chart/Chart.jsx";
import {useChillEffect} from "../../util/mounting-hooks.js";
import {setBurned24h} from "../../ducks/chart-duck.js";
import {useDispatch} from "react-redux";

export default () => {

    const dispatch = useDispatch()

    const {isPending, data = {}} = useQuery({
        queryKey: ['status'],
        queryFn: getStatusApi,
        refetchInterval: 5 * SEC,
    })

    const {
        token_balance = INITIAL_TOKEN_SUPPLY,
        circulating_supply = 0,
        token_balance_change = 0,
    } = data

    useChillEffect(()=>{
        dispatch(setBurned24h(token_balance_change))
    },[token_balance_change])

    return !isPending && (
        <>
            <Chart />
            <StatusBar
                header='token supply remaining'
                maxValue={INITIAL_TOKEN_SUPPLY}
                currentValue={token_balance}
                isBurning={true}
            />
            <StatusBar
                header='circulating supply'
                maxValue={token_balance}
                currentValue={circulating_supply}
            />
        </>
    )
}