import React from 'react';

import './Fire.scss';

const Fire = props =>
    <div className="fire-container" {...props}>
        <div className="fire">
            <div className="flames">
                <div className="flame" />
                <div className="flame" />
                <div className="flame" />
                <div className="flame" />
            </div>
        </div>
    </div>
;

export default Fire;
