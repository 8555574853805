import {useQuery} from "@tanstack/react-query";
import {getHistoryApi} from "../../api/fetch.js";
import {SEC} from "../../util/time-and-date.js";
import {Chart} from "react-chartjs-2";
import {useRef, useState} from "react";
import {INITIAL_TOKEN_SUPPLY, isMobile} from "../../util/misc.js";
import {useChillEffect} from "../../util/mounting-hooks.js";
import './Chart.scss'
import {getRelativePosition} from "chart.js/helpers";
import dayjs from "dayjs";
import ChartHighlight from "./ChartHighlight.jsx";
import {useChartHighlight} from "./useChartHighlight.jsx";

export default () => {

    const {setFocusValues, setLatestValues, resetFocusValues} = useChartHighlight()

    const chartRef = useRef()
    const [data, setData] = useState({labels: [], datasets: []})

    const {data: loadedHistory = []} = useQuery({
        queryKey: ['history'],
        queryFn: getHistoryApi,
        refetchInterval: 5 * SEC,
    })

    useChillEffect(() => {

        const chartArea = chartRef.current?.chartArea

        const ctx = document.createElement('canvas')?.getContext("2d")
        const backgroundGradient = ctx.createLinearGradient(0, chartArea?.bottom || 0, 0, chartArea?.top || 500);

        const lineColor1 = "#7f4cb4"
        const lineColor2 = "#4e4357"
        const fillColor1 = "rgba(127,76,180,0.175)"
        const fillColor2 = "rgba(127,76,180,0)"

        const linearGradient = ctx.createLinearGradient(chartArea?.left || 0, 0, chartArea?.right || 0, 0);
        linearGradient.addColorStop(1, lineColor1);
        linearGradient.addColorStop(0, lineColor2);
        backgroundGradient.addColorStop(1, fillColor1);
        backgroundGradient.addColorStop(0, fillColor2);

        const lineThickness = linearGradient.length >= 365 ? 2 : 4

        const labels = loadedHistory.map(date => date.datetime.split('T')[0])
        // const values = loadedHistory.map(date => date.token_balance)
        const values = loadedHistory.map(date => INITIAL_TOKEN_SUPPLY - date.token_balance)
        // const values = loadedHistory.map(date => date.token_balance_change * -1)

        const updateKey = "test"

        const onMouseMove = (e) => {
            if (!chartRef || !chartRef?.current) {
                return
            }
            const {x} = getRelativePosition(e, chartRef.current)
            const xOffset = chartRef.current.width / 380
            const index = chartRef.current.scales.x.getValueForPixel(x + xOffset)
            const date = dayjs(index).format("YYYY-MM-DD")
            setHoverValuesFromDate(date, updateKey)
        }

        const setHoverValuesFromDate = (date, filter) => {
            const dataIndex = labels.indexOf(date)
            const hoverDate = labels[dataIndex]
            const hoverValue = values[dataIndex]
            const hoverValuePrev = values[dataIndex - 1] || hoverValue
            setFocusValues(hoverDate, hoverValue, hoverValuePrev)
        }

        setLatestValues(labels[labels.length - 1], values[values.length - 1], values[values.length - 2])

        setData({
            onMouseMove,
            labels,
            datasets: [
                {
                    label: 'Values',
                    data: values,
                    borderColor: linearGradient,
                    backgroundColor: backgroundGradient,
                    pointRadius: 0,
                    borderWidth: isMobile() ? lineThickness / 4 * 3 : lineThickness,
                    pointHitRadius: 10,
                    lineTension: 0.15,
                    fill: true,
                },
            ].filter(dataset => !!dataset),
        })

    }, [loadedHistory.toString()])

    const options = {
        maintainAspectRatio: false,
        elements: {
            line: {
                tension: 0
            },
        },
        plugins: {
            tooltip: {
                enabled: false,
            },
            legend: {
                display: false,
            }
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    round: 'date',
                    displayFormats: {
                        month: 'MMMM YYYY',
                        date: 'MM-DD HH',
                        hour: 'MM-DD-YYYY'
                    },
                },
                border: {
                    display: !isMobile(),
                },
                ticks: {
                    // display: !isMobile(),
                    display: false,
                },
                grid: {
                    display: false,
                },
            },
            y: {
                beginAtZero: false,
                border: {
                    display: !isMobile(),
                },
                ticks: {
                    display: false,
                },
                grid: {
                    display: false,
                },
            }
        }
    }

    return (
        <div className='chart-container'>
            <Chart
                className={`no-select ${loadedHistory.length < 1 ? 'transparent' : ''}`}
                type='line'
                data={data}
                options={options}
                ref={chartRef}
                onMouseMove={data.onMouseMove}
                onTouchMove={data.onMouseMove}
                onMouseOut={resetFocusValues}
            />
            <ChartHighlight/>
        </div>
    )
}