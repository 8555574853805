import {useDispatch, useSelector} from "react-redux";
import {
    getFocusDate,
    getFocusValue, getFocusValuePrev,
    getLatestDate,
    getLatestValue, getLatestValuePrev,
    setFocusDate,
    setFocusValue, setFocusValuePrev, setLatestDate, setLatestValue, setLatestValuePrev
} from "../../ducks/chart-duck.js";

export const useChartHighlight = () => {

    const dispatch = useDispatch()

    const focusValue = useSelector(getFocusValue)
    const focusValuePrev = useSelector(getFocusValuePrev)
    const focusDate = useSelector(getFocusDate)
    const latestValue = useSelector(getLatestValue)
    const latestValuePrev = useSelector(getLatestValuePrev)
    const latestDate = useSelector(getLatestDate)

    const resetFocusValues = () => {
        dispatch(setFocusValue(null))
        dispatch(setFocusValuePrev(null))
        dispatch(setFocusDate(null))
    }

    const setFocusValues = (date, value, prevValue) => {
        dispatch(setFocusDate(date))
        dispatch(setFocusValuePrev(prevValue))
        dispatch(setFocusValue(value))
    }

    const setLatestValues = (date, value, prevValue) => {
        dispatch(setLatestDate(date))
        dispatch(setLatestValuePrev(prevValue))
        dispatch(setLatestValue(value))
    }

    return {
        setFocusValues,
        resetFocusValues,
        setLatestValues,
        value: focusValue || latestValue,
        valuePrev: focusValuePrev || latestValuePrev,
        date: focusDate || latestDate,
    }

}