import './App.scss';
import Dashboard from "./modules/dashboard/Dashboard.jsx";

function App() {
    return (
        <div className="App">
            <h1>
                <div className='ajna'>ajna</div>
                <div className='burn'>burn</div>
                <div className='dot'>.</div>
                <div className='ext'>io</div>
            </h1>
            <Dashboard/>
            <footer>
                Made and maintained by <a href="https://forum.makerdao.com/u/makerburn" target="_blank" rel="noopener noreferrer">u/makerburn</a>
            </footer>
        </div>
    );
}

export default App;
