import {createSlice} from "@reduxjs/toolkit";
import dayjs from "dayjs";

export const NAME = 'chart';

const initialState = {
    focusValue: null,
    focusValuePrev: null,
    focusDate: null,
    latestValue: 0,
    latestValuePrev: 0,
    latestDate: dayjs().format('YYYY-MM-DD'),
    burned24h: 0,
}

const {actions, reducer} = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setFocusValue: (state, {payload}) => {
            state.focusValue = payload
        },
        setFocusValuePrev: (state, {payload}) => {
            state.focusValuePrev = payload
        },
        setFocusDate: (state, {payload}) => {
            state.focusDate = payload
        },
        setLatestValue: (state, {payload}) => {
            state.latestValue = payload
        },
        setLatestValuePrev: (state, {payload}) => {
            state.latestValuePrev = payload
        },
        setLatestDate: (state, {payload}) => {
            state.latestDate = payload
        },
        setBurned24h: (state, {payload}) => {
            state.burned24h = payload
        },
        reset: () => ({
            ...initialState
        }),
    }
})

export const {
    setFocusValue,
    setFocusValuePrev,
    setFocusDate,
    setLatestValue,
    setLatestValuePrev,
    setLatestDate,
    setBurned24h,
} = actions

const getState = state => state[NAME]

export const getFocusValue = state => getState(state).focusValue
export const getFocusValuePrev = state => getState(state).focusValuePrev
export const getFocusDate = state => getState(state).focusDate
export const getLatestValue = state => getState(state).latestValue
export const getLatestValuePrev = state => getState(state).latestValuePrev
export const getLatestDate = state => getState(state).latestDate
export const getBurned24h = state => getState(state).burned24h

export default reducer