import {useChartHighlight} from "./useChartHighlight.jsx";
import './ChartHighlight.scss'
import {DECIMAL_FORMAT, numberFormat} from "../../util/numbers.js";
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import {getBurned24h} from "../../ducks/chart-duck.js";

export default () => {
    const burned24h = useSelector(getBurned24h)
    const {value, valuePrev, date} = useChartHighlight()
    const diff = value - valuePrev
    const isToday = dayjs().format('YYYY-MM-DD') === date
    const diffValue = isToday ? Math.abs(burned24h) : diff
    const isBurned = diffValue > 0.1
    return (
        <div className='chart-highlight'>
            <div className='date'>
                {date}
            </div>
            <div className='value no-burn'>
                {numberFormat(value)}<span className='label'>burned total</span>
            </div>
            {diffValue <= 0 ?
                <div className={`diff no-burn`}>
                    <span className='label'>{isToday ? 'no burn last 24h' : 'no burn'}</span>
                </div>
                :
                <div className={`diff ${isBurned ? 'is-burned' : ''} ${isToday ? 'is-today' : ''}`}>
                    {numberFormat(diffValue, DECIMAL_FORMAT.FLEX_DEC)}
                    <span className='label'>{isToday ? 'last 24h' : ''}</span>
                </div>
            }
        </div>
    )
}